import React, { useRef } from "react";
import { useState, useEffect } from "react";
import './Yourgroup.css';
import './invitegroup.css';
import { useNavigate, } from "react-router-dom";
import { getFilterOptions } from "../../store/reducers/searchReducer";
import { makeGetRequest, makePostRequest, makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import InviteGroups from "./Invitegroups";
import { useDispatch } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { useLocation } from "react-router-dom";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Joingrouppopup from "./Joingrouppopup";
import LeaveGroupPopup from "./LeaveGroupPopup";
import ThreeDotsPopupForGroup from "../Common.WebAppMate/ThreeDotsPopupForGroup";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CryptoJS from 'crypto-js';
import yourGroupImg from "../../assets/Images/yourgroups.jpg";
import LocationPopUp from "../Search.WebAppMate/LocationPopUp";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import Head from "../Layout.WebAppMate/head/Head";
import ResetResource from "../../assets/Images/reset-resource.png";
import ResetResourceWhite from "../../assets/Images/reset-resource-white.png";
import { toSeoUrl, options } from "../../services/common.services";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import { Learningpillar } from "../Common.WebAppMate/Learningpillar";
const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;
const URL = process.env.REACT_APP_URL;
/**
 * create components is used to delete child profile
 *
 * @author krishna dobariya <krishna.webappmate@gmail.com>
  * created on: 1-08-2023
 */

const Yourgroup = () => {
    const naviget = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation();
    const [myGroups, setMyGroups] = useState([])
    const [srchStatus, setSrchStatus] = useState(false);
    const [keyword, setKeyword] = useState("")
    const [invitegroup, setInvitegroup] = useState(false)
    const [spin, setSpin] = useState(false);
    const [open, setOpen] = useState("")
    const [gradeLevelData, setGradeLevelData] = useState([]);
    const [gradeRange, setGradeRange] = useState([]);
    const [gradeRangeSelected, setGradeRangeSelected] = useState([]);
    const [PillarsSelected, setPillarsSelected] = useState([])
    const [shareSelected, setShareSelected] = useState("")
    const [learningPillars, setLearningPillars] = useState([]);
    const [share, setShare] = useState([])
    const inputRef = useRef();
    const autoCompleteRef = useRef();
    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [suggestedGroup, setSuggestedGroup] = useState([]);
    const [showsuggestion, setShowSuggestion] = useState(true)
    const [showsuggestion1, setShowSuggestion1] = useState(true)
    const [joinGroupPopUp, setJoinGroupPopUp] = useState(false);
    const [groupId, setGroupId] = useState("");
    const [status, setStatus] = useState(false);
    const [searchStatus, setSearchStatus] = useState(false);
    const [distanceFromLocation, setDistanceFromLocation] = useState(0);
    const [page, setPage] = useState(1);
    const [searchPage, setSearchPage] = useState(1);
    const [copied, setCopied] = useState(false);
    const [showGroupDots, setShowGroupDots] = useState("");
    const [groupMatchId, setGroupMatchId] = useState("");
    const [upDate, setUpdate] = useState("")
    const [deniedError, setDeniedError] = useState("");
    const [locatonStatus, setLocatonStatus] = useState(false);
    const [myJoinGroups, setMyJoinGroups] = useState([]);
    const [joinPage, setJoinPage] = useState(1);
    const [joinStatus, setJoinStatus] = useState(false);
    const [searchResult, setSearchResult] = useState(false)
    const [locationPermission, setLocationPermission] = useState("prompt");
    const [suggestionPage, setSuggestionPage] = useState(1)
    const [kmCount,] = useState(0)
    const [suggestedGroupRemaining, setSuggestedGroupRemaining] = useState(0)
    const [resetMygrp, setResetMygrp] = useState(false)
    const menuRef = useRef(null);
    const [joinButtonClick, setJoinButtonClick] = useState("");
    const [, setPassKey] = useState("");
    const shareResourceOptions = [
        { id: "1", name: "Local MatchED Community" },
        { id: "2", name: "Friend" },
        { id: "3", name: "Group" }
    ];
    // this function is used for getting address by google api
    const getGoogleAddressStreetOne = () => {
        if (window.google && window.google.maps && window.google.maps.places) {
            autoCompleteRef.current = new window.google.maps.places.Autocomplete(
                inputRef.current,
                { ...options, disablePoweredByContainer: true }
            );
            autoCompleteRef.current.addListener("place_changed", async function () {
                const place = await autoCompleteRef.current.getPlace();
                let addressComponent;
                let fullAddress = "";
                if (place?.address_components) {
                    addressComponent = place.address_components;
                    addressComponent.forEach((component) => {
                        fullAddress += component.long_name + ", ";
                    });
                    fullAddress = fullAddress.slice(0, -2);
                    setAddress(fullAddress);
                }
                if (place?.geometry && place?.geometry.location) {
                    setLatitude(place.geometry.location.lat())
                    setLongitude(place.geometry.location.lng());
                }
            });
        } else {
            console.error('Google Maps API not available');
        }
    };

    useEffect(() => {
        if ((navigator.permissions && navigator.permissions.query)) {
            if (locationPermission === "prompt") {
                navigator.permissions
                    .query({ name: "geolocation" })
                    .then((permission) => {
                        setLocationPermission(permission.state);
                    });
            }
            if (locationPermission === "granted") {
                setLocationPermission("granted");
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLatitude(position.coords.latitude);
                        setLongitude(position.coords.longitude);
                    },
                    (error) => {
                        console.error(error);
                    }
                );
            }
        }
        else {

            console.error("Permissions API not supported in this environment");
        }
        document.addEventListener("mousedown", handleClickOutsideGroup, true);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideGroup, true);
        };
        // eslint-disable-next-line 
    }, []);
    //Get allow location pop up
    const locatioPremission = () => {
        if (locationPermission === "denied") {
            setDeniedError(
                "To set a new location, please enable location access in your device settings."
            );
        }
        if (deniedError) {
            setLocatonStatus(true);
        }
        if (localStorage.getItem("address")) {
            setAddress(localStorage.getItem("address"));
        }
        else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatitude(latitude);
                    setLongitude(longitude);
                    const geocoder = new window.google.maps.Geocoder();
                    const latlng = { lat: latitude, lng: longitude };
                    geocoder.geocode({ location: latlng }, (results, status) => {
                        if (status === "OK" && results.length > 0) {
                            const address = results[0].formatted_address;
                            setAddress(address);
                        } else {
                            console.error("Geocoder failed due to:", status);
                        }
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
        }
        getGoogleAddressStreetOne();
    };
    // ----------end adress funcation----------

    //This is used for set values in state if we are coming from search page after searching for group
    useEffect(() => {
        if (location?.state?.keyword) {
            setKeyword(location?.state?.keyword)
            setAddress(location?.state?.address)
            setDistanceFromLocation(location?.state?.distanceFromLocation)
            //share with options
            let arr = [];
            location?.state?.shareWith?.map((val, i) => arr.push(val.value));
            setShare(arr); // this for getting  value only to send in api 
            setShareSelected(location?.state?.shareWith) // this for selection of share with options on onload 
            //Grade range options
            let gradeArr = [];
            const gradeSelect = [];
            location?.state?.Grade?.forEach((val, i) => {
                if (val === "Grade K") {
                    gradeArr.push(1);
                    //these grades manage for auto selection in dropdown on onload of page when searching from search page 
                    gradeSelect.push({
                        "id": 1,
                        "name": "Grade K",
                        "sort": 13,
                        "status": "enable"
                    },)
                } else if (val === "Grade 1") {
                    gradeArr.push(2);
                    gradeSelect.push({
                        "id": 2,
                        "name": "Grade 1",
                        "sort": 12,
                        "status": "enable"
                    })
                } else if (val === "Grade 2") {
                    gradeArr.push(3);
                    gradeSelect.push({
                        "id": 3,
                        "name": "Grade 2",
                        "sort": 11,
                        "status": "enable"
                    })
                } else if (val === "Grade 3") {
                    gradeArr.push(4);
                    gradeSelect.push({
                        "id": 4,
                        "name": "Grade 3",
                        "sort": 10,
                        "status": "enable"
                    })
                } else if (val === "Grade 4") {
                    gradeArr.push(5);
                    gradeSelect.push({
                        "id": 5,
                        "name": "Grade 4",
                        "sort": 9,
                        "status": "enable"
                    })
                } else if (val === "Grade 5") {
                    gradeArr.push(6);
                    gradeSelect.push({
                        "id": 6,
                        "name": "Grade 5",
                        "sort": 8,
                        "status": "enable"
                    })
                } else if (val === "Grade 6") {
                    gradeArr.push(7);
                    gradeSelect.push({
                        "id": 7,
                        "name": "Grade 6",
                        "sort": 7,
                        "status": "enable"
                    })
                } else if (val === "Grade 7") {
                    gradeArr.push(8);
                    gradeSelect.push({
                        "id": 8,
                        "name": "Grade 7",
                        "sort": 6,
                        "status": "enable"
                    })
                } else if (val === "Grade 8") {
                    gradeArr.push(9);
                    gradeSelect.push({
                        "id": 9,
                        "name": "Grade 8",
                        "sort": 5,
                        "status": "enable"
                    })
                } else if (val === "Grade 9") {
                    gradeArr.push(10);
                    gradeSelect.push({
                        "id": 10,
                        "name": "Grade 9",
                        "sort": 4,
                        "status": "enable"
                    })
                } else if (val === "Grade 10") {
                    gradeArr.push(11);
                    gradeSelect.push({
                        "id": 11,
                        "name": "Grade 10",
                        "sort": 3,
                        "status": "enable"
                    })
                } else if (val === "Grade 11") {
                    gradeArr.push(12);
                    gradeSelect.push({
                        "id": 12,
                        "name": "Grade 11",
                        "sort": 2,
                        "status": "enable"
                    })
                } else if (val === "Grade 12") {
                    gradeArr.push(13);
                    gradeSelect.push({
                        "id": 13,
                        "name": "Grade 12",
                        "sort": 1,
                        "status": "enable"
                    })
                }
            });
            setGradeRange(gradeArr)  //this for getting id only to send in api
            setGradeRangeSelected(gradeSelect) // this for selected grade 
        } else {
            //These state will balnk onclick of Groups in side bar again after search
            ResetData()
        }
        if (location?.state?.status) {
            setSrchStatus(true) //this is used for stop display joined and suggestion group data at time of group search
            setSearchResult(true) //this is used for change display "search result" heading
        } else {
            setSearchResult(false)
            setSrchStatus(false)
        }
    }, [location?.state])
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setPassKey(urlParams.get('passKey'));
    }, [myGroups])
    //this function is used for searching group
    const getGroupOnSearch = () => {
        setUpdate(false)
        setSpin(true)
        const payload = {
            "page_number": searchPage,
            "grade": gradeRange,
            "learning_pillars": learningPillars,
            "share_with": share,
            "latitude": latitude,
            "longitude": longitude,
            "search": keyword,
            "item_per_page_suggestion": 4,
            "item_per_page_group": 4,
            "miles": parseInt(distanceFromLocation)
        }
        makePostRequestForAll(URLS.getMyGroup, payload)
            .then((res) => {
                console.log("search results:::", res)
                setResetMygrp(true)
                if (searchPage === 1) {
                    setMyGroups(res.data.myGroups)
                } else {
                    setMyGroups([...myGroups, ...res?.data?.myGroups]);
                }

                if (res.data.myGroupsRemaining_count === 0) {
                    setSearchStatus(false)
                } else {
                    setSearchStatus(true)
                }

                setSpin(false);


            }).catch((error) => {
                console.log("error:::", error)
                setSpin(false);
            })
    }
    // this function is used for getting those group list which is created by account owner/me
    const getGroup = () => {
        setUpdate(false)
        setSpin(true)
        const payload = {
            "page_number": page,
            "item_per_page_group": 4
        }
        makePostRequestForAll(URLS.GetMyGroupList, payload)
            .then((res) => {
                if (location?.state?.status && resetMygrp === false) {
                    setMyGroups([])
                } else {
                    if (page === 1) {
                        setMyGroups(res.data.myGroups)
                    } else {
                        setMyGroups([...myGroups, ...res?.data?.myGroups]);
                    }
                    setSpin(false);
                }
                if (res.data.myGroupsRemaining_count === 0) {
                    setStatus(false)
                } else {
                    setStatus(true)
                }
            }).catch((error) => {
                console.log("error:::", error)
                setSpin(false);
            })
    }
    //This is used for spining loader untill search result come when we are searching group from search page
    useEffect(() => {
        if (location?.state?.status && resetMygrp === false) {
            if (myGroups.length === 0) {
                setSpin(true)
            }
        }
        // eslint-disable-next-line 
    }, [resetMygrp, myGroups.length])
    // this function is calling on click of search for searching data which you want search
    const handleSearch = () => {
        setResetMygrp(true)
        if (keyword === "" && PillarsSelected.length === 0 && shareSelected.length === 0 && gradeRangeSelected.length === 0) {
            toastifyMessage("Please add keyword or select something from dropdown.", "error")
        } else {
            if (searchPage === 1) {
                getGroupOnSearch();
            }
            else {
                setSearchPage(1);
            }
            setSearchResult(true);
            setShowSuggestion(false)
            setShowSuggestion1(false)
            setSuggestedGroup([])
        }

    }
    //if we are coming from search page then search function call on onload otherwise getGroup call
    const GroupSearch = () => {
        if (searchResult) {
            getGroupOnSearch();
        }
        else {
            getGroup()
        }
    }
    //this function used for calling search api on load when searching from search page
    useEffect(() => {
        GroupSearch();
        // eslint-disable-next-line 
    }, [searchResult])
    // this function is calling on click of show more button of my groups
    useEffect(() => {
        if (page > 1) {
            getGroup()
        }
        // eslint-disable-next-line 
    }, [page]);

    // This is will work when we search data onclick of search icon and click on show more of search results
    useEffect(() => {
        if (searchPage > 1) {
            getGroupOnSearch();
        }
        // eslint-disable-next-line 
    }, [searchPage]);

    // this function giving those group list in which i am member or i joined that group
    const getJoinedGroup = () => {
        setSpin(true)
        const payload = {
            "page_number": joinPage,
            "page_number_suggestion": "",
            "grade": "",
            "learning_pillars": "",
            "share_with": "",
            "latitude": "",
            "longitude": "",
            "search": "",
            "item_per_page_suggestion": 2,
            "item_per_page": 4,
            "miles": ""
        }
        makePostRequestForAll(URLS.joinGroupList, payload)
            .then((res) => {
                if (joinPage === 1) {
                    setMyJoinGroups(res?.data?.myGroups)
                } else {
                    setMyJoinGroups([...myJoinGroups, ...res?.data?.myGroups]);
                }
                if (res?.data?.myGroupsRemaining_count === 1) {
                    setJoinStatus(true)
                } else {
                    setJoinStatus(false)
                }
                setSpin(false);
            }).catch((error) => {
                console.log("error:::", error)
            })
    }

    useEffect(() => {
        getJoinedGroup();
        // eslint-disable-next-line 
    }, [joinPage])
    // this function is use for get grade
    const getGradeLevelData = () => {
        makeGetRequest(URLS.usergetallgradelevel, null, null, null)
            .then((res) => {
                if (res.code === 200) {
                    setGradeLevelData(res.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // this is used for getting these functions data onload of page
    useEffect(() => {
        getGradeLevelData()
        dispatch(getFilterOptions());
        getSuggestion();
        getGoogleAddressStreetOne()
        window.scrollTo({ top: 0, behavior: "smooth" });
        document.body.classList.add("suggestedyourGroup");
        return () => {
            document.body.classList.remove("suggestedyourGroup");
        };
        // eslint-disable-next-line 
    }, []);

    // this function is giving suggested group list
    const getSuggestion = () => {
        setSpin(true)
        const data = {
            "item_per_page_group": 4,
            "page_number": suggestionPage,
            "count_km": kmCount
        }
        makePostRequest(URLS.groupSuggestion, null, data, null)
            .then((res) => {
                console.log("res:::::", res, suggestionPage)
                if (res?.code === 200) {
                    if (suggestionPage === 1) {
                        setSuggestedGroup(res?.data?.suggestedGroup)
                        setSuggestedGroupRemaining(res?.data?.suggestedGroupRemaining_count)
                    } else {
                        res?.data?.suggestedGroup.length > 0 && setSuggestedGroup([...suggestedGroup, ...res?.data?.suggestedGroup])
                        setSuggestedGroupRemaining(res?.data?.suggestedGroupRemaining_count)
                    }
                    setSpin(false)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        getSuggestion()
        // eslint-disable-next-line 
    }, [suggestionPage, kmCount])
    // this functions are use for handler---------
    const handleGrade = (selectedList) => {
        let arr = [];
        selectedList?.map((val, i) => arr.push(val.id));
        setGradeRange(arr);
        setGradeRangeSelected(selectedList)
    };

    const handleChange =(e) =>
    {
        if (e.key === 'Enter') {
            handleSearch();
          }

    }

    // this fuction is used for accepting invitation--------
    const acceptInvitation = (id, status, group_id, groupName, where) => {
        const payload = {
            "action_id": id,
            "status": status
        };
        makePostRequestForAll(URLS.UpdateAccpedReject, payload)
            .then((res) => {
                toastifyMessage(res.message, "success");
                if (status === "accepted") {
                    setTimeout(() => {
                        naviget(`/groupjoin/${group_id.toString()}/${toSeoUrl(groupName)}`)
                    }, 1000);
                } else if (where === "sgroup") {
                    getSuggestion();
                    setSuggestionPage(1)
                }
                else if (where === "mygroup") {
                    getGroupOnSearch();
                    setSearchPage(1);
                }
            })
            .catch((error) => {
                setSpin(false)
                console.log("error", error);
            });
    };
    // by this getting those group which shared with frnds, group, local matched community
    const handleShareWith = (selectedList) => {
        let arr = [];
        selectedList?.map((val, i) => arr.push(val.id));
        setShare(arr);
        setShareSelected(selectedList)
    };
    // ----------end handler----------

    //   this function is used for copy group link
    const handleCopyClick = (textToCopy, name) => {
        navigator.clipboard.writeText(`${URL}/groupjoin/${textToCopy}/${toSeoUrl(name)}`)
            .then(() => {
                setCopied(true);
            })
            .catch(error => {
                console.error('Error copying text:', error);
            });
    };

    // by this function we are reseting all data
    const ResetData = () => {
        setResetMygrp(true)
        setAddress("")
        setGradeRange([]);
        setGradeRangeSelected([]);
        setPillarsSelected([]);
        setShareSelected([]);
        setMyGroups([]);
        setLongitude("");
        setLatitude("");
        setKeyword("");
        setShare([]);
        setLearningPillars([]);
        setDistanceFromLocation(0);
        setShowSuggestion(true);
        setShowSuggestion1(true);
        setSearchResult(false)
        setPage(1);
        setSrchStatus(false)
        setUpdate(true)
    }
    useEffect(() => {
        if (upDate) {
            getGroup();
            getSuggestion();
        }
        // eslint-disable-next-line 
    }, [upDate])

    const menuGroupRef = useRef(null);
    const menuGroupBtnRef = useRef(null);
    //Closing three dot popup whenever clicking outside
    const handleClickOutsideGroup = (e) => {
        if (
            menuGroupRef.current &&
            !menuGroupRef?.current?.contains(e.target) &&
            !menuGroupBtnRef?.current?.contains(e.target)
        ) {
            setShowGroupDots(false);
        }
    };
    // used for reset page if you are refreshing page
    useEffect(() => {
        // Remove location state on component mount
        const { history } = window;
        history.replaceState(null, '');
    }, []);


    return (<>

        {locatonStatus && (
            <LocationPopUp
                handleResourceSaveClose={() => setLocatonStatus(false)}
            />
        )}
        <Head title="Groups"
            content="Build and support your community by starting a group to share resources, start group conversations, organize events, and provide updates through the bulletin board."
            name="Groups" ></Head>
        <div className="col-lg-10 col-md-12 col-sm-12">
            {
                spin ? <div className="sharepopupOuter backgroundOpacity1">
                    <FourDotsLoader />
                </div> :
                    <>
                        <div className="group-search-bar my-5">
                            <div className="input-search-div">
                                <label>Keywords</label>
                                <input
                                    type="text"
                                    className="input-btn-icon-icon"
                                    placeholder="Keyword Search"
                                    onChange={(e) => setKeyword(e.target.value)}
                                    value={keyword}
                                    name="keywords"
                                    onKeyPress={handleChange}
                                />
                            </div>
                            <div className="input-search-div graderangeselectdiv">
                                <label>Grade Range</label>
                                <Multiselect
                                    options={gradeLevelData}
                                    onSelect={handleGrade}
                                    onRemove={handleGrade}
                                    selectedValues={gradeRangeSelected}
                                    showCheckbox
                                    displayValue="name"
                                    placeholder="Grade Range"
                                />
                            </div>
                            <div className="input-search-div">
                                <label>Shared With</label>
                                <Multiselect
                                    options={shareResourceOptions}
                                    onSelect={handleShareWith}
                                    onRemove={handleShareWith}
                                    selectedValues={shareSelected}
                                    showCheckbox
                                    displayValue="name"
                                    placeholder="Shared With"
                                />
                            </div>
                            <button onClick={handleSearch} className="search-btn">
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                        <div className="your-group">
                            <div className="flex-d-div">
                                <div className="location-search">
                                    <div className="location-input">
                                        <input type="text" placeholder="Enter Location" onChange={(e) => setAddress(e.target.value)} ref={inputRef}
                                            value={address ? address : ""} />
                                        <GpsFixedIcon
                                            className="location-icon mr-2"
                                            onClick={locatioPremission}
                                        />
                                    </div>
                                    <div className="miles-from-s">
                                        <input
                                            type="number"
                                            className="miles"
                                            onChange={(e) =>
                                                setDistanceFromLocation(e.target.value)
                                            }
                                            value={distanceFromLocation}
                                            placeholder="Miles from search"
                                            min={0}
                                        />
                                        <button onClick={handleSearch}>
                                            <i className="fa fa-search iconsearch22" />
                                        </button>
                                    </div>
                                </div>
                                <div className="btn2-mygroup">
                                    <button className="btn yourgroupFloat create-group-btn" onClick={() => naviget("/create-new-group", { state: "" })}>Create Group</button>
                                    <button className="btn yourgroupFloat create-group-btn mx-2" onClick={ResetData} >
                                        <img src={ResetResource} alt="Reset Resource images" width="20" className="resourcehoverImg" />
                                        <img src={ResetResourceWhite} alt="Reset Resource images" width="20" className="resourceNormalImg" />
                                    </button>

                                </div>
                            </div>
                            {searchResult === true ?
                                <>{myGroups.length > 0 ?
                                    <div className="your-group-inner">
                                        <h1>Search Results</h1>
                                        <h3>You can join and view these groups.</h3>
                                    </div> : ""
                                }
                                </>
                                :
                                <>
                                    {(myGroups.length === 0 && myJoinGroups.length === 0) || (myGroups.length > 0 && myJoinGroups.length > 0)
                                        || (myGroups.length > 0 && myJoinGroups.length === 0) ?
                                        <div className="your-group-inner">
                                            <h1>My Groups</h1>
                                            <h3>Edit your groups and invite friends to join.</h3>
                                        </div> : ""}
                                </>
                            }
                            <div className="row main-group-div customenewdesign">
                                {
                                    myGroups?.length > 0 ? myGroups?.map((val, index) => {
                                        return <>
                                            <div className="col-sm-6 col-md-6 col-lg-6 my-2">
                                                <div className="background-bgColor">
                                                    <div className="groupImgBoxdescriptionOuter">
                                                        <div className="groupImgBox"
                                                            onClick={() => naviget(`/groupjoin/${val.id.toString()}/${toSeoUrl(val.group_name)}`)}
                                                            style={{ cursor: "pointer" }}>
                                                            <img src={val.image} alt="group-img" loading="lazy" />
                                                        </div>
                                                        <div className="grouparticle">
                                                            <div className="secret">
                                                                <h2 className="text-capitalize"
                                                                    onClick={() => naviget(`/groupjoin/${val.id.toString()}/${toSeoUrl(val.group_name)}`)}
                                                                    style={{ cursor: "pointer" }}>{val.group_name}</h2>
                                                                <span className="groupIconUser"><i className="fa fa-user"></i> ({val?.no_of_user})</span>
                                                                {
                                                                    val.member_group && val.admin && <span className="three-ellipis-group " ref={menuGroupBtnRef}
                                                                        onClick={() => { setShowGroupDots(!showGroupDots); setGroupMatchId(index) }}>
                                                                        <i className="fa fa-ellipsis-v"></i>
                                                                        {
                                                                            showGroupDots && groupMatchId === index && <ThreeDotsPopupForGroup val={() => setShowGroupDots(!showGroupDots)} id={val?.id} name={val.group_name} forwardedref={menuGroupRef} invite={() => {
                                                                                setInvitegroup(true);
                                                                                setGroupId(val?.id);
                                                                                window.scroll({
                                                                                    top: 0,
                                                                                    behavior: "smooth",
                                                                                });
                                                                            }} value={1} />
                                                                        }
                                                                    </span>
                                                                }
                                                            </div>
                                                            <div className="text-curriculum11">
                                                                {Array.isArray(val?.tbl_group_learning_pillars) &&
                                                                    val.tbl_group_learning_pillars.map((item, i) => (
                                                                        Learningpillar(item.tbl_learning_pillar_master.name, i)
                                                                    ))}
                                                            </div>
                                                            <div className="articleTextpara articletexthe"
                                                                onClick={() => naviget(`/groupjoin/${val.id}/${toSeoUrl(val.group_name)}`)}
                                                                style={{ cursor: "pointer" }}>
                                                                <p dangerouslySetInnerHTML={{ __html: val?.about_group }}></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="groupImgBoxdescriptionOuter d-flex justifyouter">
                                                        <div className="content-text-bsvg">
                                                            <p className="my-1" onClick={() => handleCopyClick(val.id, val.group_name)}>Copy link to share</p>
                                                            <div className="copy-icon" onClick={() => handleCopyClick(val.id, val.group_name)}>
                                                                <div className="tooltip">{copied ? "copied" : "Copy Text"}</div>
                                                                <ContentCopyIcon className="mt-2" />

                                                            </div>
                                                        </div>
                                                        <div className="group-text-button">
                                                            {
                                                                val.member_group && !val.admin && <>
                                                                    <button className="join-group mx-1" onClick={() => { setGroupId(val.id); setOpen(true) }}>Leave</button>
                                                                    <button className="view-group"
                                                                        onClick={() => naviget(`/groupjoin/${val.id}/${toSeoUrl(val.group_name)}`)}>View</button>
                                                                </>
                                                            }
                                                            {
                                                                val.member_group && val.admin && <>
                                                                    <button className="invite mx-1" onClick={() => { setGroupId(val.id); setInvitegroup(!invitegroup) }}>Invite</button>
                                                                    <button className="edit" onClick={() => naviget(`/create-new-group/${btoa(CryptoJS.AES.encrypt(val.id.toString(), secretKey).toString())}`, { state: "" })}>Edit</button>
                                                                </>
                                                            }
                                                            {
                                                                val.member_group === false && val.admin === false && <>
                                                                    {val.req_group === false ?
                                                                        <button className="join-group mx-1" onClick={() => { setGroupId(val.id); setJoinGroupPopUp(true); setJoinButtonClick("searchApi") }}>Join</button>
                                                                        : val?.action_group === "action" ? <> <button className="join-group "
                                                                            onClick={() =>
                                                                                acceptInvitation(val?.action_id, "accepted", val.id, val.group_name, "mygroup")
                                                                            } >Accept </button>
                                                                            <button className="join-group "
                                                                                onClick={() =>
                                                                                    acceptInvitation(val?.action_id, "rejected", val.id, val.group_name, "mygroup")
                                                                                }>Reject </button></> : <button className="join-group mx-1" >Pending </button>
                                                                    }
                                                                    <button className="view-group"
                                                                        onClick={() => naviget(`/groupjoin/${val.id}/${toSeoUrl(val.group_name)}`)}>View</button>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }) :
                                        <>
                                            {location?.state?.status && resetMygrp === false ? "" :
                                                <>
                                                    {(myGroups.length === 0 && myJoinGroups.length === 0 && searchResult === false) ?

                                                        <div className="nodataFoundinfo">
                                                            <strong className="text-center">Build and support your community by starting a group to share resources, start group conversations, organize events, and provide updates through the bulletin board.</strong>
                                                            <img src={yourGroupImg} className="save img-fluid" style={{ width: "100%" }} alt="no data" loading="lazy" />
                                                        </div>

                                                        : <>
                                                            {myGroups.length > 0 && location?.state?.status ?
                                                                "" : <div className="your-group-inner" style={{ paddingTop: "100px" }}><h1>No data found</h1> </div>}

                                                        </>}
                                                </>
                                            }
                                        </>
                                }
                            </div>
                            <div className="show-more">
                                {status && searchResult === false && myGroups.length > 0 ? <p className="text-center" onClick={() => setPage(page + 1)}>Show more</p> : <>{searchResult === true && myGroups.length > 0 && searchStatus ? <p className="text-center" onClick={() => setSearchPage(searchPage + 1)}>Show more</p> : ""}</>}
                            </div>
                            {showsuggestion &&
                                <>{(myJoinGroups?.length > 0 && myGroups?.length > 0 && srchStatus === false) || (myJoinGroups?.length === 0 && myGroups?.length === 0 && srchStatus === false)
                                    || (myJoinGroups?.length > 0 && myGroups?.length === 0 && srchStatus === false) ? <div className="your-group-inner">
                                    <h1>Joined Groups</h1>
                                    <h3>View groups you have already joined and share with friends.</h3>
                                </div> : ""
                                }
                                </>
                            }
                            <div className="row main-group-div customenewdesign">
                                {
                                    myJoinGroups?.length > 0 && showsuggestion && srchStatus === false ? myJoinGroups.map((val, index) => {
                                        return (
                                            <div className="col-sm-6 col-md-6 col-lg-6 my-2">
                                                <div className="background-bgColor">
                                                    <div className="groupImgBoxdescriptionOuter">
                                                        <div className="groupImgBox"
                                                            onClick={() => naviget(`/groupjoin/${val.id.toString()}/${toSeoUrl(val.group_name)}`)}
                                                            style={{ cursor: "pointer" }}>
                                                            <img src={val.image} alt="group-img" loading="lazy" />
                                                        </div>
                                                        <div className="grouparticle">
                                                            <div className="secret">
                                                                <h2 className="text-capitalize"
                                                                    onClick={() => naviget(`/groupjoin/${val.id.toString()}/${toSeoUrl(val.group_name)}`)}
                                                                    style={{ cursor: "pointer" }}>{val.group_name}</h2>
                                                                <span className="groupIconUser"><i className="fa fa-user"></i> ({val.total_member})</span>
                                                            </div>
                                                            <div className="text-curriculum11">
                                                                {Array.isArray(val?.tbl_group_learning_pillars) &&
                                                                    val.tbl_group_learning_pillars.map((item, i) => (
                                                                        Learningpillar(item.tbl_learning_pillar_master.name, i)
                                                                    ))}
                                                            </div>
                                                            <div className="articleTextpara" onClick={() => naviget(`/groupjoin/${val.id.toString()}/${toSeoUrl(val.group_name)}`)} style={{ cursor: "pointer" }}>
                                                                <p dangerouslySetInnerHTML={{ __html: val?.about_group }}></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="groupImgBoxdescriptionOuter d-flex justifyouter">
                                                        <div className="content-text-bsvg">
                                                            <p className="my-1" onClick={() => handleCopyClick(val.id, val.group_name)}>Copy link to share</p>
                                                            <div className="copy-icon" onClick={() => handleCopyClick(val.id, val.group_name)}>
                                                                <div className="tooltip">{copied ? "copied" : "Copy Text"}</div>
                                                                <ContentCopyIcon className="mt-2" />
                                                            </div>
                                                        </div>
                                                        <div className="group-text-button">
                                                            {
                                                                val.member_group && !val.admin && <>
                                                                    <button className="join-group mx-1" onClick={() => { setGroupId(val.id); setOpen(true) }}>Leave</button>
                                                                    <button className="view-group"
                                                                        onClick={() => naviget(`/groupjoin/${val.id.toString()}/${toSeoUrl(val.group_name)}`)}
                                                                    >View</button>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : <>     {location?.state?.status && resetMygrp === false ? "" :
                                        <>  {showsuggestion &&
                                            <>{myGroups.length === 0 && myJoinGroups.length === 0 ? <div className="nodataFoundinfo">
                                                <strong className="text-center">You currently have not joined any groups. </strong>
                                                <img src={yourGroupImg} className="save img-fluid" style={{ width: "100%" }} alt="no found" loading="lazy" />
                                            </div> : ""}
                                            </>
                                        }
                                        </>
                                    }
                                    </>
                                }
                            </div>
                            <div className="show-more">
                                {showsuggestion === true && joinStatus && srchStatus === false && <p className="text-center" onClick={() => setJoinPage(joinPage + 1)}>Show more</p>}
                            </div>
                            {
                                showsuggestion1 && srchStatus === false && <div className="suggest-group">
                                    <h1>Suggested Groups</h1>
                                    <h4>Groups you might be interested in.</h4>
                                </div>
                            }
                            <div className="row main-group-div customenewdesign">
                                {
                                    showsuggestion1 && suggestedGroup?.length > 0 && srchStatus === false ? suggestedGroup?.map((val, index) => {
                                        return (
                                            <div className="col-sm-6 col-md-6 col-lg-6 my-2">
                                                <div className="background-bgColor">
                                                    <div className="groupImgBoxdescriptionOuter">
                                                        <div className="groupImgBox"
                                                            onClick={() => naviget(`/groupjoin/${val.id}/${toSeoUrl(val.group_name)}`)}
                                                            style={{ cursor: "pointer" }}>
                                                            <img src={val.image} alt="group-img" loading="lazy" />
                                                        </div>
                                                        <div className="grouparticle">
                                                            <div className="secret">
                                                                <h2 className="text-capitalize"
                                                                    onClick={() => naviget(`/groupjoin/${val.id.toString()}/${toSeoUrl(val.group_name)}`)}
                                                                    style={{ cursor: "pointer" }}>{val.group_name}</h2>
                                                                <span className="groupIconUser"><i className="fa fa-user"></i> ({val.no_of_user})</span>
                                                            </div>
                                                            <div className="text-curriculum11">
                                                                {Array.isArray(val?.tbl_group_learning_pillars) &&
                                                                    val.tbl_group_learning_pillars.map((item, i) => (
                                                                        Learningpillar(item.tbl_learning_pillar_master.name, i)
                                                                    ))}
                                                            </div>
                                                            <div className="articleTextpara"
                                                                onClick={() => naviget(`/groupjoin/${val.id}/${toSeoUrl(val.group_name)}`)}
                                                                style={{ cursor: "pointer" }}>
                                                                <p dangerouslySetInnerHTML={{ __html: val?.about_group.length > 170 ? val?.about_group : val?.about_group?.slice(0, 170) }}></p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="groupImgBoxdescriptionOuter d-flex justifyouter">
                                                        <div className="content-text-bsvg">
                                                            <p className="my-1" onClick={() => handleCopyClick(val.id, val.group_name)}>Copy link to share</p>
                                                            <div className="copy-icon" onClick={() => handleCopyClick(val.id, val.group_name)}>
                                                                <div className="tooltip">{copied ? "copied" : "Copy Text"}</div>
                                                                <ContentCopyIcon className="mt-2" />
                                                            </div>
                                                        </div>
                                                        <div className="group-text-button">
                                                            {val.invite_group === "Join" ?
                                                                <button className="join-group " onClick={() => { setGroupId(val.id); setJoinGroupPopUp(true); setJoinButtonClick("suggestionApi") }}>Join </button>
                                                                : val?.action_group === "action" ? <> <button className="join-group "
                                                                    onClick={() =>
                                                                        acceptInvitation(val?.action_id, "accepted", val?.id, val.group_name, "sgroup")
                                                                    }  >Accept </button>
                                                                    <button className="join-group "
                                                                        onClick={() =>
                                                                            acceptInvitation(val?.action_id, "rejected", val.id, val.group_name, "sgroup")
                                                                        } >Reject</button></> : <button className="join-group " >Pending </button>
                                                            }
                                                            <button className="view-group mx-2"
                                                                onClick={() => naviget(`/groupjoin/${val.id}/${toSeoUrl(val.group_name)}`)}>View</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : <>{location?.state?.status && resetMygrp === false ? "" :

                                        <>{showsuggestion1 && srchStatus === false && <div className="nodataFoundinfo">
                                            <strong className="text-center">No Data Found</strong>
                                            <img src={yourGroupImg} className="save img-fluid" style={{ width: "100%" }} alt="not found" loading="lazy" />
                                        </div>} </>
                                    }
                                    </>
                                }
                            </div>
                            <div className="show-more mb-5">
                                {(showsuggestion1 && suggestedGroupRemaining === 1 && suggestedGroup?.length > 0 && srchStatus === false) && <p className="text-center" onClick={() => setSuggestionPage(suggestionPage + 1)}>Show more</p>}
                            </div>
                            {
                                invitegroup === true ? <InviteGroups datar={() => setInvitegroup(!invitegroup)} value={groupId} /> : null
                            }
                            {joinGroupPopUp === true ? (
                                <Joingrouppopup datar={() => setJoinGroupPopUp(!joinGroupPopUp)} value={groupId} forwardedref={menuRef} joinfrom={joinButtonClick} getSuggestion={() => { suggestionPage === 1 ? getSuggestion() : setSuggestionPage(1) }}
                                    getSearch={() => { getGroupOnSearch() }}
                                />
                            ) : null
                            }
                            {
                                open && <LeaveGroupPopup
                                    open={open}
                                    handleClose={() => setOpen(false)}
                                    value={groupId}
                                    funcall={() => { if (searchResult) { getGroupOnSearch(); setSearchPage(1) } setJoinPage(1); getJoinedGroup(); }}
                                    lastAdmin="User"
                                />
                            }
                        </div>
                    </>
            }
        </div>

    </>);
}
export default Yourgroup;